import React from "react"
import TestimonialSwiper from "../swiper/testimonial-swiper"
import ReadMoreReact from "read-more-react"
import data from "../../assets/testimonials.json"

const Testimonials = () => {
  return (
    <div className="container-fluid review-section reviews-section px-0">
      <div className="waves-top">
        <svg width="100%" height="200px" fill="none">
          <path
            fill="#FFF"
            d="
          M0 67
          C 273,183
            822,-40
            1920.00,106 

          V 359 
          H 0 
          V 67
          Z"
          >
            <animate
              repeatCount="indefinite"
              fill="#454599"
              attributeName="d"
              dur="15s"
              values="
            M0 77 
            C 473,283
              822,-40
              1920,116 

            V 359 
            H 0 
            V 67 
            Z; 

            M0 77 
            C 473,-40
              1222,283
              1920,136 

            V 359 
            H 0 
            V 67 
            Z; 

            M0 77 
            C 973,260
              1722,-53
              1920,120 

            V 359 
            H 0 
            V 67 
            Z; 

            M0 77 
            C 473,283
              822,-40
              1920,116 

            V 359 
            H 0 
            V 67 
            Z
            "
            ></animate>
          </path>
        </svg>
      </div>

      <div className="waves-bottom">
        <svg width="100%" height="200px" fill="none">
          <path
            fill="#FFF"
            d="
          M0 67
          C 273,183
            822,-40
            1920.00,106 

          V 359 
          H 0 
          V 67
          Z"
          >
            <animate
              repeatCount="indefinite"
              fill="#454599"
              attributeName="d"
              dur="15s"
              values="
            M0 77 
            C 473,283
              822,-40
              1920,116 

            V 359 
            H 0 
            V 67 
            Z; 

            M0 77 
            C 473,-40
              1222,283
              1920,136 

            V 359 
            H 0 
            V 67 
            Z; 

            M0 77 
            C 973,260
              1722,-53
              1920,120 

            V 359 
            H 0 
            V 67 
            Z; 

            M0 77 
            C 473,283
              822,-40
              1920,116 

            V 359 
            H 0 
            V 67 
            Z
            "
            ></animate>
          </path>
        </svg>
      </div>

      <div id="review-section-wrap" className="container py-5">
        <div className="mb-5">
          <h2 className="text-center text-white mt-0">Our Customers</h2>
          <p className="text-center text-white">
            Don't just take our word for it! We've been servicing Colorado
            serving for 20 years and have helped hundreds of homeowners achieve
            the perfect home loan. Here's what some of them have to say:
          </p>
        </div>

        <TestimonialSwiper>
          {data.map((item, index) => (
            <div key={index}>
              <div
                className="card mx-2 review-item"
                style={{ minHeight: 380, borderRadius: 25 }}
              >
                <div className="card-body review-statement d-flex flex-column justify-content-between">
                  <div className="mt-5">
                    <ReadMoreReact
                      className="text-center"
                      text={item.text}
                      readMoreText="Read More"
                    />
                  </div>
                  <div className="text-center">
                    <span className="stars">
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                      <i className="fa fa-star" />
                    </span>
                    <p className="mb-0 mt-2" style={{ color: "#36a5b9" }}>
                      {item.name}
                    </p>
                    <p
                      className="m-0"
                      style={{ color: "#000", fontWeight: 300 }}
                    >
                      {item.role}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </TestimonialSwiper>
      </div>
    </div>
  )
}

export default Testimonials
