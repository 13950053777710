import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import { Container } from "reactstrap"
// import Swiper from "../components/swiper/swiper"
// import SampleForm from "../components/form/form"
// import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

import mouseWheel from "../images/mouse-wheel.gif"
import proActive from "../images/communication.svg"
import simpleProc from "../images/process.svg"
import lightningFast from "../images/fast.svg"

// import topWave from "../images/top-wave.svg"
// import bottomWave from "../images/bottom-wave.svg"

// import client1 from "../images/pp1.jpg"
// import client2 from "../images/pp2.jpg"
// import client3 from "../images/pp3.jpg"

import blob1 from "../images/blob1.svg"
import blob2 from "../images/blob2.svg"
import blob3 from "../images/blob3.svg"

// import aimeAccredited from "../images/aime_email_badge.png"
// import Slide from "react-reveal/Slide"
// import Flip from "react-reveal/Flip"

import Testimonials from "../components/testimonials/testimonials"

class IndexPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    const posts = this.props.data.allMarkdownRemark.edges
    // console.log(posts)
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Mission Mortgage" description={siteDescription} />

        <div className="container-fluid hero-section">
          <div className="row">
            <div className="col-md-5 vertical-align d-none-mobile">
              <div className="brandstamp">
                <h1>On a Mission to provide a better home loan experience</h1>
                <p className=" pb-3 pt-3">
                  We help you find the right product, at the best price, and
                  provide the highest level of service with our personalized
                  mortgage solutions
                  <br />
                </p>
                <div className="button-div">
                  <a
                    href="https://plus.preapp1003.com/Darlene-Franklin"
                    className="btn-global mr-3"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apply Now
                  </a>
                  <Link
                    to="/loan-process"
                    className="btn-global-inverted"
                    type="button"
                  >
                    Our Process
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-7 hero-img vertical-align">
              <div className="brandstamp-mobile text-white">
                <h1>On a Mission to provide a better home loan experience</h1>
                <p className="mb-5">
                  We help you find the right product, at the best price, and
                  provide the highest level of service with our personalized
                  mortgage solutions
                  <br />
                  <br />
                </p>
                <div className="button-div">
                  <Link to="https://plus.preapp1003.com/Darlene-Franklin" className="btn-global mr-3" type="button">
                    Apply Now
                  </Link>
                  <Link
                    to="/loan-process"
                    className="btn-global-inverted"
                    type="button"
                  >
                    Our Process
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 text-center scroll-down">
          <Link to="/#the-mission-box" className="mouse-wheel-box">
            <img className="mouse-wheel" src={mouseWheel} alt="Scroll Down" />
          </Link>
        </div>

        <div
          className="container mission-section text-center"
          id="the-mission-box"
          style={{ paddingTop: "10rem" }}
        >
          <div className="apply-container">
            <div className="row">
              <div className="col-md-10 mission-div mx-auto">
                <h2 className="mt-3 mb-2">The Mission</h2>
                <p>
                  To provide each and every client with personalized mortgage
                  solutions at the best financing terms possible, and to create
                  a memorable home loan experience they'll want to share with
                  family and friends.
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container vp-section">
          <h2 className="text-center mb-5">How We Help</h2>
          <div className="row text-center">
            <div className="col-lg-3 vp-props mx-auto">
              <div className="vp-props-inner">
                <img src={proActive} alt="Pro-active Communication" />
                <h5 className="mt-3 ">
                  <strong>Pro-Active Communication</strong>
                  <br />
                </h5>
                <p>
                  During the mortgage process, we are committed to keeping you
                  up to date. You'll always be in the know, and any questions
                  will be answered quickly.
                </p>
              </div>
            </div>
            <div className="col-lg-3 vp-props side-shadow mx-auto">
              <div className="vp-props-inner">
                <img src={simpleProc} alt="Simple Process" />
                <h5 className="mt-3">
                  <strong>Simple Process</strong>
                  <br />
                </h5>
                <p>
                  You'll follow our proven road map to success as we take you
                  from pre-approval to close -- and we'll be there guiding you
                  every step of the way.
                </p>
              </div>
            </div>
            <div className="col-lg-3 vp-props mx-auto">
              <div className="vp-props-inner">
                <img src={lightningFast} alt="Lightning Fast" />
                <h5 className="mt-3">
                  <strong>Lightning Fast</strong>
                  <br />
                </h5>
                <p>
                  We aren't just fast, we're extremely fast... We'll work with
                  your timeframe to ensure that you get the perfect loan
                  on-time, every time -- guaranteed.
                </p>
              </div>
            </div>
          </div>
        </div>

        <Testimonials />

        {/* <div
          className="container-fluid"
          style={{
            boxShadow:
              "0px 10px 5px rgb(226, 217, 255), 0px -10px 5px rgb(226, 217, 255)",
          }}
        >
          <div className="row align-items-center mt-5">
            <div className="col-lg-12 text-center">
              <Flip left duration={1500}>
                <div className="step-img-wrapper mt-5">
                  <img
                    src={aimeAccredited}
                    alt="Processing and Underwriting"
                    style={{ width: "15rem" }}
                  />
                </div>
              </Flip>
            </div>
            <div className="col-lg-12 text-center">
              <Slide left duration={1500}>
                <h3 className="card-title mt-5 mb-5">
                  Proud to be Locally Owned and Operated
                </h3>
              </Slide>
            </div>
          </div>
        </div> */}

        <div className="container svc-section">
          <h2 className="text-center">We do things differently:</h2>
          <p className="text-center mb-5">
            There are a number of ways we make your life easier. Here are just a
            few:
            <br />
          </p>
          <div className="row pt-4 pb-5 align-items-center">
            <div className="col-md-6 p-relative">
              <div className="svc-container svc-img-1"></div>
              <div className="blob1">
                <img src={blob1} alt="blob 1" />
              </div>
            </div>
            <div className="col-md-6 vertical-align">
              <div className="svc-content">
                <h4 className="mb-2">
                  <strong>Elite Mortgage Insurance</strong>
                  <b />
                </h4>
                <p className="mb-3">
                  For those putting less than 20% down, Mission Mortgage
                  Colorado offers some of the lowest Mortgage Insurance rates
                  available anywhere!
                  <br />
                </p>
                <h5>
                  <strong>KEY FEATURES</strong>
                  <br />
                </h5>
                <ul>
                  <li>
                    Starts at 640 credit scrore
                    <br />
                  </li>
                  <li>
                    Among the lowest mortgage insurance rates in the industry
                    <br />
                  </li>
                  <li>
                    Instant Mortgage Insurance Certificate - saves 3-7 days on
                    the loan process
                    <br />
                  </li>
                  <li>
                    None of the mortgage insurance underwriting overlays that
                    other companies have (debt-to-income, number of
                    borrowers...)
                    <br />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row pt-5 pb-5 flex-column-reverse flex-md-row align-items-center">
            <div className="col-md-6 vertical-align">
              <div className="svc-content">
                <h4 className="mb-2">
                  <strong>
                    Free Escrow Waivers On Conventional Loans -- up to 95% LTV
                  </strong>
                  <br />
                </h4>
                <p className="mb-3">
                  Escrow waivers up to 95% LTV is unheard of, you won't find
                  this anywhere else.&nbsp;AND we don't charge the fees commonly
                  associated with waiving escrows - it's free!
                  <br />
                </p>
                <h5>
                  <strong>KEY FEATURES</strong>
                  <br />
                </h5>
                <ul>
                  <li>
                    Escrow waivers up to 95% LTV
                    <br />
                  </li>
                  <li>
                    No escrow waiver fee
                    <br />
                  </li>
                  <li>
                    Eliminates initial escrow deposit
                    <br />
                  </li>
                  <li>
                    Available on all conventional loans
                    <br />
                  </li>
                  <li>
                    Option to waive just taxes, just insurance or both
                    <br />
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="svc-container svc-img-2"></div>
              <div className="blob2">
                <img src={blob2} alt="blob 2" />
              </div>
            </div>
          </div>
          <div className="row pt-5 pb-5 align-items-center">
            <div className="col-md-6">
              <div className="svc-container svc-img-3 "></div>
              <div className="blob3">
                <img src={blob3} alt="blob 3" />
              </div>
            </div>
            <div className="col-md-6 vertical-align">
              <div className="svc-content">
                <h4 className="mb-2">
                  <strong>VIRTUAL E-CLOSING</strong>
                  <br />
                </h4>
                <p className="mb-2">
                  You have the option to close when you want, where you want,
                  completely online -- from the comfort of your living room,
                  while celebrating at your favorite restaurant, or anywhere
                  with Wi-Fi access.
                  <br />
                </p>
                <p className="mb-3">
                  Our option of a virtual E-close allows you to close without
                  wet-signing a single document by verifying your ID with a
                  notary through video chat.
                  <br />
                </p>
                <h5>
                  <strong>KEY FEATURES</strong>
                  <br />
                </h5>
                <ul>
                  <li>
                    Faster, more convenient closing process
                    <br />
                  </li>
                  <li>
                    E-sign all documents, including the mortgage promissory
                    note, for anywhere that has Wi-Fi access
                    <br />
                  </li>
                  <li>
                    No in-person signing needed
                    <br />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row pt-5 pb-5 flex-column-reverse flex-md-row align-items-center">
            <div className="col-md-6 vertical-align">
              <div className="svc-content">
                <h4 className="mb-2">
                  <strong>DOC-LESS INCOME AND ASSETS</strong>
                  <br />
                </h4>
                <p className="mb-3">
                  We're revolutionizing the mortgage industry yet again by
                  combining our E-sign technology with systems that securely and
                  automatically verify income, assets, and tax returns.
                  <br />
                </p>
                <h5>
                  <strong>KEY FEATURES</strong>
                  <br />
                </h5>
                <ul>
                  <li>
                    No more bank statements
                    <br />
                  </li>
                  <li>
                    No more pay stubs
                    <br />
                  </li>
                  <li>
                    No more tax returns
                    <br />
                  </li>
                  <li>
                    Just E-sign and go
                    <br />
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="svc-container svc-img-4 p-relative"></div>
              <div className="blob1">
                <img src={blob1} alt="blob 1" />
              </div>
            </div>
          </div>
          <div className="row pt-5 pb-5 align-center">
            <div className="col-md-6">
              <div className="svc-container svc-img-5"></div>
              <div className="blob3">
                <img src={blob3} alt="blob 3" />
              </div>
            </div>
            <div className="col-md-6 vertical-align">
              <div className="svc-content">
                <h4 className="mt-0 mb-2">
                  <strong>FLEX TERM FINANCING</strong>
                  <br />
                </h4>
                <p className="mb-3">
                  Customize the perfect loan without resetting the mortgage
                  clock! Flex Term allows you to choose your own amortization
                  term!&nbsp;
                  <br />
                </p>
                <h5>
                  <strong>KEY FEATURES</strong>
                  <br />
                </h5>
                <ul>
                  <li>
                    Available on all conventional loan products
                    <br />
                  </li>
                  <li>
                    Terms available from 8–30 years
                    <br />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="container blog-section">
          <h2 className="text-center mt-0">
            Read Our Latest Blog
            <br />
          </h2>
          <p className="m-auto text-center pb-5">
            We believe in empowering Colorado to better understand the factors
            that go into making smart home finance decisions. Take a look at our
            blog to discover how to treat your home as an investment:
            <br />
          </p>

          <div className="row">
            {posts.map(blog => (
              <div
                className="col-lg-4 col-md-6 col-sm-12 mt-5"
                key={blog.node.id}
                style={{ background: "#fff" }}
              >
                <Link
                  to={blog.node.fields.slug}
                  // to="/loan-programs/va-home-loans"
                  className="lprog-wrapper"
                >
                  <div
                    className="card apply-container p-0"
                    style={{ border: "none" }}
                  >
                    <div
                      className="blog-img-box"
                      style={{
                        height: "12rem",
                        backgroundImage: `url(/${
                          blog.node.frontmatter.banner !== null
                            ? blog.node.frontmatter.banner
                            : "https://source.unsplash.com/user/joshhild/500x500"
                        })`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    />
                    <div className="card-body">
                      <h4 className="card-title mt-3 mb-3">
                        {blog.node.frontmatter.title}
                      </h4>
                      {/* <p
                              className="card-text"
                              style={{
                                width: "100%",
                                height: "auto",
                                color: "#5f5f71",
                              }}
                            >
                              {blog.node.frontmatter.excerpt.length >= 100
                                ? blog.node.frontmatter.excerpt.substring(
                                    0,
                                    100
                                  ) + "..."
                                : blog.node.frontmatter.excerpt}
                            </p> */}
                      <div className="footer-blog-wrap">
                        <div className="row align-items-center">
                          <div className="col-lg-12">
                            <div className="author-face mt-3 d-flex justify-content-between align-items-center">
                              {/* <img
                                      src={blogAuthor}
                                      alt="BLog Author"
                                      className="img-fluid rounded-circle float-left mr-3"
                                      width={60}
                                    /> */}
                              <p className="m-0">
                                {/* <span class="mr-3">
                                        by: {blog.node.frontmatter.author}
                                      </span> */}
                                <span className="text-muted small">
                                  <i className="fa fa-calendar-o pr-1" />
                                  {blog.node.frontmatter.publishedDate}
                                </span>
                              </p>
                              <span to={blog.node.fields.slug} className="ml-3">
                                Read Now
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
            {/* <div className="col-md-4">
              <div className="blog-container  mb-5">
                <div className="blog-img-2"></div>
                <div className="blog-content">
                  <h4 className="m-3">
                    Is a Recession Here? Yes. Does that Mean a Housing Crash?
                    No.
                  </h4>
                  <div className="row px-3">
                    <div className="col-6 pl-3 pb-2">
                      <p className="m-0">Jun 2020</p>
                    </div>
                    <div className="col-6 text-right">
                      <a href="#">Read Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-4">
              <div className="blog-container  mb-5">
                <div className="blog-img-3"></div>
                <div className="blog-content">
                  <h4 className="m-3">
                    Is a Recession Here? Yes. Does that Mean a Housing Crash?
                    No.
                  </h4>
                  <div className="row px-3">
                    <div className="col-6 pl-3 pb-2">
                      <p className="m-0">Jun 2020</p>
                    </div>
                    <div className="col-6 text-right">
                      <a href="#">Read Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className="apply-section">
          <div className="protruded-gradient"></div>
          <div className="container">
            <div className="apply-container">
              <div className="row align-items-center">
                <div className="col-md-8">
                  <div>
                    <h2 className="mb-2 mt-3">
                      Let's get started
                      <br />
                    </h2>
                    <p className="pb-4">
                      Getting pre-approved can be quick and easy. Start the
                      process today!
                      <br />
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <div className="col">
                      <a
                        href="https://plus.preapp1003.com/Darlene-Franklin"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn-global w-100 mb-3"
                      >
                        Apply Now
                      </a>
                    </div>
                    <div className="col">
                      <Link
                        to="/loan-process"
                        className="btn-global-inverted w-100 text-center"
                        style={{ padding: "10px 20px" }}
                      >
                        Our Process
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: frontmatter___publishedDate, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            banner
            publishedDate(formatString: "MMMM DD, YYYY")
            excerpt
            author
            metaTitle
            metaKeywords
            metaDescription
          }
        }
      }
    }
  }
`
