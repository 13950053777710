import React, { useRef } from "react"
import Swiper from "react-id-swiper"
import { Navigation, Pagination } from "swiper/js/swiper.esm"
import "swiper/css/swiper.css"

const TestimonialSwiper = ({ children }) => {
  const swiperRef = useRef(null)

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext()
    }
  }
  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev()
    }
  }

  const params = {
    modules: [Pagination, Navigation],
    nested: true,
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 0,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 0,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
    },
  }
  return (
    <div style={{ position: "relative" }}>
      <button onClick={goPrev} className="testimonial-swiper-nav btn-left">
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          className="bi bi-chevron-compact-left"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"
          />
        </svg>
      </button>
      <div className="testimonial-main-wrapper">
        <Swiper {...params} ref={swiperRef}>
          {children}
        </Swiper>
      </div>
      <button onClick={goNext} className="testimonial-swiper-nav btn-right">
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          className="bi bi-chevron-compact-right"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
          />
        </svg>
      </button>
    </div>
  )
}

export default TestimonialSwiper
